import * as React from "react";
import { graphql } from 'gatsby';
import { Layout } from "../Layout";
import { Description } from "../Description";
import { Info, InfoBoxContainer } from "../InfoBox";
import { PageTitle } from "../PageTitle";
import { Gallery } from "../Gallery";
import { TopBox, MainImageContainer } from "../TopBox";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Head = ({ data }) => {
  const metadata = data.lightship.frontmatter;
  const description = `${metadata.name} Lightship, first established in ${metadata.yearEstablished}`;

  return (
    <>
      <title>{metadata.name} | Worldwide Lighthouses</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={metadata.name} />
      <meta property="og:site_name" content="Worldwide Lighthouses" />
      <meta property="og:url" content="https://worldwidelighthouses.com" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="place" />
      { 
        data.images.edges
          .map(edge => getImage(edge.node).images)
          .map(image => 
            <meta 
              property="og:image" 
              content={image.fallback.src}
            />
          )
      }
    </>
  )
}

const Lightship = ({ data }) => {
  const lightship = data.lightship;
  const metadata = lightship.frontmatter;

  return (
    <Layout>
      <PageTitle title={metadata.name} />
      <TopBox>
        <MainImageContainer>
        { data.images?.edges?.[0]?.node &&
          <GatsbyImage 
            image={getImage(data.images.edges[0].node)}
            alt={`${metadata.name} Lightship`} 
            style={{ 
              borderRadius: "8px",
              marginTop: "10px",
              border: "3px solid #1E1E1E",
              width: "100%"
            }} 
            imgStyle={{
              borderRadius: "5px"
            }}
          />
        }
        </MainImageContainer>
        <InfoBoxContainer>
          {metadata.name && <Info title="Name" value={metadata.name} /> }
          {metadata.yearBuilt && <Info title="Year Built" value={metadata.yearBuilt} /> }
          {metadata.yearRetired && <Info title="Year Retired" value={metadata.yearRetired} /> }
          {metadata.lengthInFeet && <Info title="Length (ft)" value={metadata.lengthInFeet} /> }
          {metadata.beamInFeet && <Info title="Beam (ft)" value={metadata.beamInFeet} /> }
          {metadata.draughtInFeet && <Info title="Draught (ft)" value={metadata.draughtInFeet} /> }
          {metadata.shipyard && <Info title="Shipyard" value={metadata.shipyard} /> }
          {metadata.operator && <Info title="Operator" value={metadata.operator} /> }
        </InfoBoxContainer>
      </TopBox>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: lightship.html }} />
      </Description>
      { data.images?.edges && data.images?.edges.length > 0 &&
        <Gallery>
          {data.images.edges.slice(1).map(edge => 
            <GatsbyImage 
              style={{ borderRadius: "8px", border: "3px solid #1E1E1E", width: "100%"}} 
              image={getImage(edge.node)} 
              alt={`${metadata.name} Lightship`}
              imgStyle={{ borderRadius: "5px" }}
            /> 
          )}
        </Gallery>
      }
    </Layout>
  );
};

export default Lightship;

export const Query = graphql`
  query LightshipQuery($id: String!, $urlEncodedName: String!) {
    lightship: markdownRemark(id: { eq: $id }) {
      html,
      frontmatter {
        name,
        yearBuilt,
        yearRetired,
        lengthInFeet,
        beamInFeet,
        shipyard,
        operator,
      }
    }
    images: allFile(
      filter: {
        relativeDirectory: { eq: $urlEncodedName }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      },
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 530
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
